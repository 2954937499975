import { ProductPreviewCardBase } from '@/entities/product';
import { AddToCartButton } from '@/features/add-to-cart-button';
import { AddToFavoriteButton } from '@/features/add-to-favorite-button';
import { useCityStore } from '@/shared/store/cityStore';
import { useFavoritesStore } from '@/shared/store/favoritesStore';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { ComponentProps, FC, useMemo } from 'react';

interface Props
  extends Omit<ComponentProps<typeof ProductPreviewCardBase>, 'addToFavoriteButtonSlot' | 'addToCartButtonSlot'> {
  onClick?: () => void;
  isBgGray?: boolean;
  isLoading?: boolean;
  onSearchPopup?: boolean;
}

export const ProductPreviewCard: FC<Props> = ({
  className,
  onClick,
  isBgGray,
  isLoading = false,
  onSearchPopup = false,
  ...props
}) => {
  // const isSale = !!props.label.find(
  //   (i) => i.id === 'sale'
  // );

  const city = useCityStore((state) => state.city);

  const cityShops = useMemo(() => {
    if (!city) return [];

    return props.amount?.find((i) => i.city.code === city)?.store || [];
  }, [city]);

  const cityName = useCityStore((state) => state.cityName);

  const defaultShopAmount = useMemo(() => {
    const defaultCity = props.amount?.find((i) => i.city.code === 'default');
    const defaultShop = defaultCity?.store.find((i) => i.store.uf_mainstore_delivery);
    return Math.max(defaultShop?.amount || 0, 0);
  }, []);

  const shopsAmount = useMemo(() => {
    if (!city) {
      return [];
    }

    const storesAvailable = cityShops.filter((i) => i.amount > 0) || [];

    if (storesAvailable.length > 0) {
      return [
        {
          name: cityName,
          coordinates: storesAvailable[0].store.map,
          totalAmount: Math.max(
            storesAvailable.reduce((acc, i) => acc + i.amount, 0),
            0
          ),
          shops: storesAvailable.map((i) => ({
            ...i.store,
            amount: i.amount,
          })),
        },
      ];
    }

    return [];
  }, [city, cityName]);

  const totalAmount = useMemo(() => {
    return (
      Math.max(...(shopsAmount[0]?.shops.length ? shopsAmount[0].shops.map((shop) => shop.amount) : [0])) +
      (defaultShopAmount || 0)
    );
  }, [shopsAmount, defaultShopAmount]);

  const isAvailable = !!(shopsAmount[0]?.totalAmount || defaultShopAmount || 0);

  const favoriteProducts = useFavoritesStore((state) => state.favoriteProducts);
  const isProductsFavorite = favoriteProducts?.find((id) => id === props.id);

  return (
    <ProductPreviewCardBase
      isBgGray={isBgGray}
      // isSale={isSale}
      onClick={onClick}
      isLoading={isLoading}
      className={twMergeExt(
        cvaRoot(),
        // isSale ? '!bg-cYellowCardOnGrey' : '',
        className
      )}
      onSearchPopup={onSearchPopup}
      isUnavailable={!isAvailable}
      addToFavoriteButtonSlot={
        <AddToFavoriteButton
          size={'small'}
          className={cvaButton({
            mobileSize: props.mobileVariant,
            onSearchPopup,
            isBgWhite: isBgGray,
          })}
          id={props.id}
          active={Boolean(isProductsFavorite)}
        />
      }
      addToCartButtonSlot={
        <AddToCartButton
          size={'small'}
          className={cvaButton({
            mobileSize: props.mobileVariant,
            onSearchPopup,
            isBgWhite: false,
          })}
          id={props.id}
          totalAmount={totalAmount}
        />
      }
      {...props}
    />
  );
};

const cvaRoot = cva(['ProductPreviewCard-cvaRoot']);

const cvaButton = cva(
  ['ProductPreviewCard-cvaButton', 'min-h-[3.2rem] sm:min-h-[4rem]', 'p-0.8 sm:p-1.2', 'sm:w-4.8 sm:h-4.8 group/like'],
  {
    variants: {
      mobileSize: {
        default: 'w-4 h-4',
        catalog: 'w-3.2 h-3.2',
      },
      onSearchPopup: {
        true: ['!w-3.2 sm:!w-4 !h-3.2 sm:!h-4', '!rounded-[1.2rem] sm:!rounded-[1.2rem]'],
      },
      isBgWhite: {
        true: 'bg-cWhite',
      },
    },
    defaultVariants: {
      mobileSize: 'default',
      isBgWhite: false,
    },
  }
);
