import { postFavoriteAdd } from '@/shared/api/postFavoriteAdd';
import { postFavoriteDelete } from '@/shared/api/postFavoriteDelete';
import { useFavoritesStore } from '@/shared/store/favoritesStore';
import { Button } from '@/shared/ui/Button';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { ComponentProps, FC, useEffect, useState } from 'react';
import HeartIcon from '~/icons/heart-stroked.svg';

interface Props {
  className?: string;
  size?: 'large' | 'small';
  active?: boolean;
  id: number;
}

export const AddToFavoriteButton: FC<Props> = ({ className, size = 'small', active, id }) => {
  const [isActive, setIsActive] = useState(active);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const addFavoriteProduct = useFavoritesStore((state) => state.addFavoriteProduct);
  const deleteFavoriteProduct = useFavoritesStore((state) => state.deleteFavoriteProduct);

  const handleClick = async (e) => {
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    e.stopPropagation();
    try {
      setIsLoading(true);
      const response = isActive
        ? await postFavoriteDelete({
            product_id: id,
          })
        : await postFavoriteAdd({
            products: [id],
          });

      if (response?.status === 'success') {
        setIsActive(!isActive);

        if (isActive) {
          deleteFavoriteProduct(id);
        } else {
          addFavoriteProduct(id);
        }
      }
    } catch (e: any) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Button
      onClick={handleClick}
      className={twMergeExt(cvaRoot({ size }), className, size === 'large' ? 'lg:h-5.6 lg:w-5.6' : '')}
      isLoading={isLoading}
      heartIcon
      color={
        {
          large: 'white',
          small: 'mediumGreyOnWhite',
        }[size] as ComponentProps<typeof Button>['color']
      }
      size={'small'}
    >
      <HeartIcon className={cvaIcon({ active: isActive })} />
    </Button>
  );
};

const cvaRoot = cva(
  [
    'AddToFavoriteButton-cvaRoot',
    'flex justify-center items-center',
    '[&>div]:overflow-visible',
    'w-4.8 h-4.8',
    'group/like',
  ],
  {
    variants: {
      size: {
        large: '!p-0',
        small: '!p-0',
      },
    },
  }
);

const cvaIcon = cva(
  [
    'AddToFavoriteButton-cvaIcon',
    'w-1.6 sm:w-2.4 h-1.6 sm:h-2.4 text-cBlack48',
    'group-hover/like:text-[#FF5E7F]',
    'transition-all duration-300',
  ],
  {
    variants: {
      active: {
        true: '!text-[#FF5E7F] fill-[#FF5E7F]',
        false: '',
      },
    },
  }
);
