'use client';
import { useCartStore } from '@/shared/store/cartStore';
import AddToCartNotify from '@/shared/ui/AddToCartNotify/AddToCartNotify';
import { Button } from '@/shared/ui/Button';
import { Portal } from '@/shared/ui/Portal';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import CartIcon from '~/icons/cart.svg';

interface Props {
  className?: string;
  size?: 'large' | 'small';
  id: number;
  disabled?: boolean;
  totalAmount?: number;
}

export const AddToCartButton: FC<Props> = ({ className, size = 'small', id, disabled, totalAmount }) => {
  const addToCart = useCartStore((state) => state.addToCart);

  const cart = useCartStore((state) => state.cart);
  const alreadyAdded = cart && cart.some((item) => item.product.id === id);

  const alreadyAddedTotalAmount = (alreadyAdded && cart && cart.find((item) => item.product.id === id)?.quantity) || 0;

  const [showAddToCartPopup, setShowAddToCartPopup] = useState(false);

  const [showNotAvailablePopup, setShowNotAvailablePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickButton = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.nativeEvent.stopImmediatePropagation();
    e.preventDefault();
    e.stopPropagation();
    // Не даем заказать если товаров в магазине впритык, что бы на складе всегда был хотя бы один товар
    if (alreadyAddedTotalAmount < (totalAmount || 0)) {
      setIsLoading(true);
      await addToCart(id);
      setIsLoading(false);
      setShowAddToCartPopup(true);
    } else {
      setShowNotAvailablePopup(true);
    }
  };
  return (
    <>
      <Button
        className={twMergeExt(
          cvaRoot({
            size: size,
            alreadyAdded: alreadyAdded,
          }),
          className,
          size === 'large' ? 'w-full lg:h-5.6' : ''
        )}
        alreadyAdded={alreadyAdded}
        disabled={disabled}
        contentWrapperClassName={cvaWrapper()}
        animateWrapperClassName={size === 'large' ? 'w-full' : ''}
        color={'mediumGreen'}
        size={'small'}
        hoverEffect={'highlight'}
        onClick={handleClickButton}
        isLoading={isLoading}
        disableInViewAnimation={true}
      >
        {size === 'large' ? (alreadyAdded ? 'Добавить еще' : 'Добавить в корзину') : null}
        <CartIcon className={cvaIcon()} />
      </Button>
      <AnimatePresence>
        {showAddToCartPopup ? (
          <Portal>
            <AddToCartNotify onClose={() => setShowAddToCartPopup(false)} />
          </Portal>
        ) : null}
      </AnimatePresence>
      <AnimatePresence>
        {showNotAvailablePopup ? (
          <Portal>
            <AddToCartNotify onClose={() => setShowNotAvailablePopup(false)} disabled />
          </Portal>
        ) : null}
      </AnimatePresence>
    </>
  );
};

const cvaRoot = cva(['AddToCartButton-cvaRoot', 'text-bodyMSemibold'], {
  variants: {
    size: {
      large: '!p-0',
      small: '!p-0 !text-cWhite',
    },
    alreadyAdded: {
      true: ['!bg-[#DFFAD5] text-cBlack hnotdisable:text-cMediumGreen'],
    },
  },
});

const cvaWrapper = cva(['AddToCartButton-cvaWrapper', 'flex justify-center items-center gap-0.8']);

const cvaIcon = cva(['AddToCartButton-cvaIcon', 'w-1.6 sm:w-2.4 h-1.6 sm:h-2.4']);
