import { API } from '../api';
import { axiosInstance } from '../axios';
import { IFavoriteData } from '../postFavoriteAdd/types';
import { FavoriteDeleteParams } from './types';

export const postFavoriteDelete = async (body: FavoriteDeleteParams) => {
  try {
    const result = await axiosInstance.post<IFavoriteData>(API.postFavoriteDelete, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return result.data;
  } catch (e: any) {
    console.error(e);
  }
};
