'use client';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { SplitText } from '@cyriacbr/react-split-text';
import { cva } from 'class-variance-authority';
import { Children, ElementType, FC, memo, ReactElement, ReactNode, useCallback, useMemo } from 'react';
import s from './Title.module.css';

interface Props {
  className?: string;
  as?: ElementType;
  delay?: number;
  trigger?: boolean;
  children: ReactNode;
}

// Мемоизируем компонент Title
export const Title: FC<Props> = memo(({ className, as = 'div', delay = 0, trigger, children }) => {
  const Component = useMemo(() => as, [as]);

  // Используем useCallback для мемоизации cbRef
  const cbRef = useCallback(
    (instance: HTMLElement | null) => {
      if (instance) {
        const lines = instance.querySelectorAll(`.${s.line}`);

        lines.forEach((line, index) => {
          (line as HTMLElement).style.animationDelay = `${delay + 0.1 * index}s`;
        });
      }
    },
    [delay] // cbRef будет перезаписан только при изменении delay
  );

  // Мемоизация children, чтобы предотвратить их ререндер при одинаковом значении
  const memoizedChildren = useMemo(() => {
    if (trigger) {
      return (
        <SplitContentByLine trigger={trigger} delay={delay}>
          {children}
        </SplitContentByLine>
      );
    }

    return children;
  }, [trigger, delay, children]);

  return (
    <Component
      ref={cbRef}
      className={twMergeExt(cvaRoot(), s.wrapper, trigger && s.isTriggered, className)}
      style={{ transitionDelay: `${delay}s` }}
    >
      {memoizedChildren}
    </Component>
  );
});

// Мемоизируем компонент SplitContentByLine
const SplitContentByLine = memo(
  ({
    className,
    trigger,
    delay = 0,
    children,
  }: {
    className?: string;
    trigger: boolean;
    delay?: number;
    children: ReactNode;
  }) => {
    // Мемоизация каждой строки текста и компонентов в children
    return useMemo(
      () =>
        Children.toArray(children).map((child, index) => {
          if (typeof child === 'string') {
            return (
              // @ts-expect-error: ?
              <SplitText
                key={index}
                className={twMergeExt('inline', className)}
                // @ts-expect-error: ?
                LineWrapper={({ lineIndex, children }) => {
                  const beforeFirstLineNbsp = child.match(/^[(&nbsp;)| ]+/gm);
                  return (
                    <div className={twMergeExt(s.line, trigger && s.isTriggered, 'inline-block')}>
                      {lineIndex === 0 ? beforeFirstLineNbsp?.join('') : null}
                      {children}
                    </div>
                  );
                }}
              >
                {child}
              </SplitText>
            );
          } else {
            const { className, children } = (child as ReactElement).props;
            return (
              <SplitContentByLine
                key={index}
                className={twMergeExt(className, 'inline-block')}
                trigger={trigger}
                delay={delay}
              >
                {children}
              </SplitContentByLine>
            );
          }
        }),
      [children, className, trigger, delay] // Зависимости для предотвращения ререндеров
    );
  }
);

Title.displayName = 'Title';
SplitContentByLine.displayName = 'SplitContentByLine';

const cvaRoot = cva(['Title-cvaRoot']);
