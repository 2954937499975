export const productTagsDataMock = [
  {
    id: 1,
    title: 'new',
    icon: '/icons/flower-white.svg',
    color: '#3DC869',
  },
  {
    id: 2,
    title: 'hit',
    icon: '/icons/star-white.svg',
    color: '#6CBCF7',
  },
  {
    id: 3,
    title: '1+1',
    icon: '/icons/gift-white.svg',
    color: '#A065FE',
  },
  {
    id: 4,
    title: 'sale',
    icon: '/icons/fire-white.svg',
    color: '#FFB016',
  },
];

export const productTagsDataByTitleMock = Object.fromEntries(productTagsDataMock.map((i) => [i.title, i]));
