'use client';
import { FC, useEffect, useState } from 'react';
import { cva } from 'class-variance-authority';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { Swiper } from 'swiper/types';
import { useSwiper } from 'swiper/react';
import { motion, useMotionTemplate, useMotionValue } from 'framer-motion';

interface Props {
  className?: string;
  currentProgressNumberClassName?: string;
  totalProgressNumberClassName?: string;
  swiper?: Swiper | null;
  forceCurrentSlide?: number;
  forceTotal?: number;
}

export const SliderProgress: FC<Props> = ({
  className,
  currentProgressNumberClassName,
  totalProgressNumberClassName,
  swiper,
  forceCurrentSlide,
  forceTotal,
}) => {
  const swiperContext = useSwiper() ?? swiper;

  const [currentSlide, setCurrentSlide] = useState(forceCurrentSlide ?? 1);
  const [totalSlides, setTotalSlides] = useState(forceTotal ?? 1);

  useEffect(() => {
    const handler = (swiper: Swiper) => {
      const current =
        forceCurrentSlide ?? Math.ceil((swiper?.realIndex ?? 0) / (swiper?.params?.slidesPerGroup ?? 1) + 1);
      setCurrentSlide(current);

      const total = forceTotal ?? Math.ceil((swiper?.slides?.length ?? 1) / (swiper?.params?.slidesPerGroup ?? 1));
      setTotalSlides(total);
    };

    handler(swiperContext);
    swiperContext?.on('realIndexChange', handler);

    return () => {
      swiperContext?.off('realIndexChange', handler);
    };
  }, [swiperContext, forceCurrentSlide, forceTotal]);

  const progressIndicatorWidth = useMotionValue(0);
  const progressBackgroundSize = useMotionTemplate`${progressIndicatorWidth}% 100%`;

  useEffect(() => {
    const handler = (swiper: Swiper, timeLeft: number, percentage: number) => {
      progressIndicatorWidth.set(100 - Number.parseFloat((percentage * 100).toFixed(2)));
    };

    swiperContext?.on('autoplayTimeLeft', handler);

    return () => {
      swiperContext?.off('autoplayTimeLeft', handler);
    };
  }, [swiperContext]);

  return (
    <div className={twMergeExt(cvaRoot(), className)}>
      <span className={twMergeExt(cvaProgressNumber({ type: 'current' }), currentProgressNumberClassName)}>
        {currentSlide > 9 ? currentSlide : `0${currentSlide}`}
      </span>
      <div className={cvaProgressWrapper()}>
        <motion.div
          className={cvaProgressIndicator()}
          style={{
            backgroundSize: progressBackgroundSize,
          }}
        />
      </div>
      <span className={twMergeExt(cvaProgressNumber({ type: 'total' }), totalProgressNumberClassName)}>
        {totalSlides > 9 ? totalSlides : `0${totalSlides}`}
      </span>
    </div>
  );
};

const cvaRoot = cva(['SliderProgress-cvaRoot', 'flex items-center gap-1.6', 'text-bodyMSemibold']);

const cvaProgressWrapper = cva(['SliderProgress-cvaProgress', 'relative', ' w-3.2 h-[0.15rem]', 'bg-cBlack16']);

const cvaProgressIndicator = cva([
  'SliderProgress-cvaProgressIndicator',
  'h-full',
  'bg-gradient-to-r from-cMediumGreen to-cMediumGreen bg-no-repeat',
  'transition-colors duration-300',
]);

const cvaProgressNumber = cva(['SliderProgress-cvaProgressNumber', 'inline-block', 'min-w-1.6 sm:min-w-2'], {
  variants: {
    type: {
      current: 'text-cBlack',
      total: 'text-cBlack32',
    },
  },
});
